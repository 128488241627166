import { render, staticRenderFns } from "./transferToBalance.vue?vue&type=template&id=bd9d1530&scoped=true&"
import script from "./transferToBalance.vue?vue&type=script&lang=js&"
export * from "./transferToBalance.vue?vue&type=script&lang=js&"
import style0 from "./transferToBalance.vue?vue&type=style&index=0&id=bd9d1530&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd9d1530",
  null
  
)

export default component.exports